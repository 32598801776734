import {React, useState} from "react";
import { useTranslation } from "react-i18next";
import ProjectModal from './ProjectModal';
import Project from "./Project";
import "./project.css";
import project1 from "../../assets/images/projects/project-1.jpg";
import project2 from "../../assets/images/projects/project-2.jpg";
import project3 from "../../assets/images/projects/project-3.png";
import project4 from "../../assets/images/projects/project-4.png";
import project5 from "../../assets/images/projects/project-5.png";
import project6 from "../../assets/images/projects/project-6.png";
import project7 from "../../assets/images/projects/project-7.png";
import project8 from "../../assets/images/projects/project-8.png";

const projectsData = [
  {
    image: project7,
    title: "project 7 title",
    description: "project 7 description",
    githubUrl: "",
    youtubeUrl: "https://www.youtube.com/embed/Lg_EeET_4Og?si=gNOZAcGdJuCemZsC",
  },
  {
    image: project1,
    title: "project 1 title",
    description: "project 1 description",
    githubUrl: "https://github.com/lucasri-silva/C-to-Python-Compiler",
    youtubeUrl: "",
  },
  {
    image: project2,
    title: "project 2 title",
    description: "project 2 description",
    githubUrl: "https://github.com/lucasri-silva/graph-algorithms-analysis",
    youtubeUrl: "",
  },
  {
    image: project3,
    title: "project 3 title",
    description: "project 3 description",
    githubUrl: "https://github.com/lucasri-silva/8-queen-problem/tree/main",
    youtubeUrl: "",
  },
  {
    image: project4,
    title: "project 4 title",
    description: "project 4 description",
    githubUrl: "https://github.com/lucasri-silva/processos-e-threads",
    youtubeUrl: "",
  },
  {
    image: project5,
    title: "project 5 title",
    description: "project 5 description",
    githubUrl: "https://github.com/lucasri-silva/triagem_chatbot",
    youtubeUrl: "",
  },
  {
    image: project6,
    title: "project 6 title",
    description: "project 6 description",
    githubUrl: "https://github.com/lucasri-silva/arvore-B",
    youtubeUrl: "",
  },
  {
    image: project8,
    title: "project 8 title",
    description: "project 8 description",
    githubUrl: "https://github.com/lucasri-silva/RGB-cube",
    youtubeUrl: "",
  },
];

const ProjectsList = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const { t } = useTranslation();

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <section id="projects" className="projects">
      <div className="projects container">
        <div className="section-title">
          <h2>{t('projects title')}</h2>
        </div>
        <div className="projects-list container">
          {projectsData.map((project, index) => (
            <div key={index} className="project-link">
              <div onClick={() => {
                if (project.youtubeUrl) {
                  openModal(project);
                } else {
                  window.open(project.githubUrl, "_blank");
                }
              }}>
                <Project
                  image={project.image}
                  title={t(project.title)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedProject && <ProjectModal project={selectedProject} onClose={closeModal} />}
    </section>
  );
};

export default ProjectsList;
