import React, { useState } from "react";
import i18n from "../../i18n.js";
import "./language.css";

const Language = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  return (
    <div className="language__container">
      <button
        className={`language__item ${
          currentLanguage === "en" ? "translated" : "untranslated"
        }`}
        onClick={() => changeLanguage("en")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icons-gb"
          viewBox="0 0 512 512"
          width="15px"
          height="15px"
        >
          <path fill="#012169" d="M0 0h512v512H0z" />
          <path
            fill="#FFF"
            d="M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z"
          />
          <path
            fill="#C8102E"
            d="m184 324 11 34L42 512H0v-3l184-185zm124-12 54 8 150 147v45L308 312zM512 0 320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z"
          />
          <path fill="#FFF" d="M176 0v512h160V0H176zM0 176v160h512V176H0z" />
          <path fill="#C8102E" d="M0 208v96h512v-96H0zM208 0v512h96V0h-96z" />
        </svg>
      </button>
      <button
        className={`language__item ${
          currentLanguage === "pt" ? "translated" : "untranslated"
        }`}
        onClick={() => changeLanguage("pt")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icons-br"
          viewBox="0 0 512 512"
          width="15px"
          height="15px"
        >
          <g stroke-width="1pt">
            <path fill="#229e45" fill-rule="evenodd" d="M0 0h512v512H0z" />
            <path
              fill="#f8e509"
              fill-rule="evenodd"
              d="m261.4 405.4 229.8-149.2L260 106.6l-230.7 150 232 148.8z"
            />
            <path
              fill="#2b49a3"
              fill-rule="evenodd"
              d="M361.5 256a97.2 97.2 0 1 1-194.3-.2 97.2 97.2 0 0 1 194.3.2z"
            />
            <path
              fill="#ffffef"
              fill-rule="evenodd"
              d="m232.3 314.2-3-1.8-3.1 1.6.7-3.5-2.4-2.5 3.4-.4 1.6-3.2 1.5 3.3 3.4.6-2.6 2.4m65.7 20-3-1.8-3.2 1.6.7-3.5-2.4-2.5 3.5-.4 1.6-3.2 1.4 3.3 3.4.6-2.5 2.4m-27.6-22.9-2.6-1.5-2.7 1.3.6-3-2-2.2 2.9-.3 1.4-2.7 1.2 2.8 3 .5-2.2 2m66.2-6.4-2.6-1.5-2.6 1.3.6-2.9-2-2.1 2.9-.4 1.3-2.6 1.3 2.7 2.9.5-2.2 2m-66.6-16.7-3-1.8-3.1 1.6.7-3.5-2.4-2.5 3.4-.4 1.6-3.1 1.5 3.2 3.4.6-2.6 2.4M188 245l-3-1.8-3 1.6.6-3.5-2.4-2.5 3.5-.4 1.6-3.2 1.4 3.3 3.4.6-2.5 2.4m10.1 43.5-3-1.7-3.1 1.5.7-3.4-2.4-2.6 3.4-.4 1.6-3 1.5 3.1 3.4.7-2.6 2.3m100.6-51.3-2.6-1.5-2.8 1.3.6-3-2-2.3 3-.3 1.4-2.8 1.3 2.9 3 .5-2.3 2.1m-5 29.2L290 255l-2.1 1 .4-2.4-1.6-1.7 2.4-.3 1.1-2.2 1 2.3 2.4.4-1.8 1.6m-108.4 38.5-2-1.2-2.1 1 .4-2.3-1.6-1.7 2.4-.2 1-2 1 2 2.3.5-1.7 1.6m152.6 11.5-1.7-.8-1.7.7.4-1.7-1.3-1.3 1.9-.2.9-1.5.7 1.6 1.9.3-1.4 1.2"
            />
            <path
              fill="#ffffef"
              fill-rule="evenodd"
              d="m183.5 292.3-2-1.2-2.1 1 .5-2.3-1.7-1.7 2.3-.2 1.1-2 1 2 2.3.5-1.7 1.6"
            />
            <path
              fill="#ffffef"
              fill-rule="evenodd"
              d="m183.5 292.3-2-1.2-2.1 1 .5-2.3-1.7-1.7 2.3-.2 1.1-2 1 2 2.3.5-1.7 1.6m32.2 2.3-2-1.2-2 1 .4-2.3-1.6-1.7 2.3-.2 1-2.1 1 2.1 2.3.5-1.7 1.6m-3.7 13-2-1.2-2 1 .4-2.3-1.6-1.7 2.3-.3 1-2 1 2 2.3.5-1.7 1.6m66.7-17-2-1.2-2.1 1 .4-2.3-1.6-1.7 2.3-.2 1.1-2.1 1 2.1 2.2.4-1.7 1.6m-19.1 2.4-2-1.2-2.1 1 .5-2.3-1.6-1.7 2.3-.2 1-2.1 1 2.1 2.3.4-1.7 1.6m-52.5-4.4-1.2-.7-1.3.6.2-1.5-1-1 1.5-.2.7-1.3.5 1.4 1.5.2-1 1M333.2 310l-2-1.1-2.1 1 .5-2.3-1.6-1.7 2.3-.3 1-2 1 2 2.3.5-1.7 1.6m-16 4.4-1.6-1-1.7 1 .4-2-1.4-1.4 2-.2.8-1.7.8 1.7 2 .4-1.5 1.3m8 1.8-1.6-1-1.6.9.3-1.8-1.2-1.3 1.8-.2.8-1.6.7 1.6 1.8.3-1.3 1.3m22.2-17.4-1.5-.9-1.6.8.4-1.7-1.2-1.3 1.7-.2.8-1.5.7 1.6 1.7.3-1.3 1.2M317 322.9l-2-1.1-2 1 .5-2.2-1.6-1.5 2.2-.3 1.1-1.9 1 2 2.1.4-1.6 1.4m.4 10.9-1.8-1-1.8.9.4-2.2-1.4-1.5 2-.3 1-1.9.8 2 2 .4-1.5 1.4M302.3 312l-1.5-.9-1.6.8.4-1.8-1.2-1.2 1.7-.2.8-1.6.7 1.6 1.7.3-1.3 1.2m-13.5 1.8-1.5-.9-1.6.8.4-1.8-1.2-1.2 1.7-.2.8-1.6.7 1.6 1.7.3-1.2 1.2M265 291.4l-1.5-.9-1.6.8.4-1.7-1.2-1.3 1.7-.2.8-1.5.7 1.6 1.7.3-1.3 1.1m2.9 43.5-1.3-.7-1.3.7.3-1.5-1-1 1.4-.3.7-1.3.6 1.4 1.5.2-1.1 1m-35.2-66-3-1.7-3.1 1.5.7-3.4-2.4-2.6 3.4-.4 1.6-3.1 1.5 3.2 3.4.6-2.6 2.4"
            />
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M355.1 291a95 95 0 0 0 4.4-15.1c-51.6-45.4-109.2-68.7-182-63.9a95 95 0 0 0-6.4 15.9 233 233 0 0 1 184 63z"
            />
            <path
              fill="#309e3a"
              d="m331.9 265.4 1.8 1a2.6 2.6 0 0 0-.2 1.8c.1.4.5.9 1 1.2.6.4 1.1.6 1.6.6.4 0 .8-.3 1-.6.1-.2.2-.4.1-.7l-.3-.8-1.2-1.3a5.9 5.9 0 0 1-1.4-2.3 2.8 2.8 0 0 1 1.6-3.3 2.9 2.9 0 0 1 1.7-.2 5.3 5.3 0 0 1 2 .9 6 6 0 0 1 2 2.4 3 3 0 0 1-.5 2.6l-1.8-1.1c.2-.5.3-1 .2-1.4-.1-.3-.5-.7-1-1-.5-.4-1-.5-1.4-.5a.8.8 0 0 0-.6.3.8.8 0 0 0-.1.7c0 .4.5 1 1.2 1.7l1.5 2a3 3 0 0 1-.2 3.2 3.1 3.1 0 0 1-1.4 1.1 3 3 0 0 1-1.9.2 6 6 0 0 1-2.1-1 4.6 4.6 0 0 1-2-2.5c-.3-.9-.2-1.9.4-3zm-8.8-5.7 2 1a2.6 2.6 0 0 0-.2 1.6c.1.5.5 1 1 1.3.6.4 1.1.5 1.6.4.4 0 .8-.2 1-.6a1 1 0 0 0 .1-.6c0-.3-.1-.5-.4-.8l-1.2-1.3a6 6 0 0 1-1.5-2.2 2.8 2.8 0 0 1 .3-2.4 2.8 2.8 0 0 1 1.2-1 3 3 0 0 1 1.7-.2c.6 0 1.2.3 2 .8 1 .7 1.8 1.4 2 2.3a3 3 0 0 1-.3 2.6l-1.9-1.1c.3-.5.3-1 .2-1.3-.2-.4-.5-.7-1-1a2.4 2.4 0 0 0-1.5-.5.8.8 0 0 0-.6.4.8.8 0 0 0 0 .7c0 .3.5.9 1.2 1.7.8.7 1.3 1.4 1.6 1.8a3 3 0 0 1-.1 3.3 3.2 3.2 0 0 1-3.2 1.4 6.1 6.1 0 0 1-2.2-.9 4.7 4.7 0 0 1-2.1-2.4 4.1 4.1 0 0 1 .3-3zm-10.8-3 5.6-9 6.7 4-1 1.6-4.8-3-1.3 2 4.6 2.8-1 1.6-4.5-2.8-1.5 2.5 5 3-.9 1.6-6.9-4.2zm-15.8-12.9.9-1.6 4 2.2-1.9 3.7a7.2 7.2 0 0 1-4.8-.6 5.8 5.8 0 0 1-2.2-2 4.5 4.5 0 0 1-.8-2.6c0-1 .3-1.9.8-2.8a6.1 6.1 0 0 1 2-2.3c.7-.6 1.7-.9 2.7-.9.7 0 1.6.3 2.5.7a5 5 0 0 1 2.3 2.2c.4.8.5 1.7.3 2.7l-2.1-.6a2.2 2.2 0 0 0-.2-1.5 2.5 2.5 0 0 0-1.2-1.1 2.9 2.9 0 0 0-2.4-.3c-.7.3-1.4 1-2 2a4.8 4.8 0 0 0-.5 3c.2.8.7 1.4 1.6 1.8l1.3.4h1.3l.6-1.2-2.2-1.2zm-68.8-17 1.6-10.6 3.2.5.8 7.5 3-7 3.1.5-1.5 10.6-2-.3 1.2-8.3-3.3 8-2-.3-.9-8.7-1.2 8.4-2-.3zm-10.7-1.3 1-10.6 7.8.7-.1 1.8-5.8-.5-.2 2.3 5.3.5-.1 1.8-5.3-.5-.3 3 5.9.5-.2 1.8-8-.8z"
            />
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="M181.4 218.8c0-1 .2-2 .5-2.7l1-1.4 1.5-1a5.8 5.8 0 0 1 2.3-.3 5 5 0 0 1 3.7 1.6c1 1 1.3 2.3 1.3 4 0 1.8-.6 3.1-1.5 4a5 5 0 0 1-3.8 1.4 5 5 0 0 1-3.7-1.5 5 5 0 0 1-1.3-4z"
              />
              <path
                fill="#f7ffff"
                d="M183.6 218.8c0 1.2.2 2.2.8 2.8.5.7 1.2 1 2 1a3 3 0 0 0 2.2-.9c.5-.6.8-1.5.9-2.7 0-1.3-.2-2.2-.8-2.8a2.7 2.7 0 0 0-2-1c-1 0-1.7.3-2.2.9-.6.6-.9 1.5-1 2.7z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="m194 224.4.1-10.7h4.5c1.2 0 2 .2 2.5.4s1 .5 1.2 1 .5 1 .5 1.7c0 .8-.3 1.4-.7 2-.5.5-1.2.8-2.2 1 .5.2.9.5 1.2.8l1.2 1.8 1.3 2H201l-1.5-2.3a16 16 0 0 0-1.2-1.6 1.6 1.6 0 0 0-.6-.4 3.5 3.5 0 0 0-1-.2h-.5v4.5H194z"
              />
              <path
                fill="#fff"
                d="M196.2 218.2h1.6a8.1 8.1 0 0 0 2 0l.5-.5c.2-.2.3-.5.3-.8 0-.4-.1-.7-.3-.9a1.3 1.3 0 0 0-.8-.4h-3.2v2.6z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="m206.2 214.2 3.9.2a7.6 7.6 0 0 1 2 .3 4 4 0 0 1 1.5 1 5 5 0 0 1 1 1.9c.2.7.2 1.5.2 2.5a5.3 5.3 0 0 1-1.7 4.1c-.4.3-.9.6-1.5.8h-2l-4-.1.6-10.7z"
              />
              <path
                fill="#fff"
                d="m208.2 216.1-.3 7 1.6.2h1.3l.9-.5c.3-.2.4-.5.6-1l.3-2-.1-1.8c-.2-.5-.3-.8-.6-1a2 2 0 0 0-1-.6 9.6 9.6 0 0 0-1.7-.2h-1z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="m258.5 233.3 2.5-10.4 3.3.8c1.3.3 2.1.6 2.5.8.5.3 1 .7 1.2 1.3.3.7.3 1.4.1 2.2a3 3 0 0 1-1.9 2.3 3 3 0 0 1-1.1.3 12 12 0 0 1-2.2-.4l-1.4-.3-1 3.9-2-.5z"
              />
              <path
                fill="#fff"
                d="m262.6 225.2-.7 3 1.2.2c.8.2 1.4.3 1.7.2a1.4 1.4 0 0 0 1.2-1l-.1-1.1a1.5 1.5 0 0 0-.8-.7l-1.5-.4-1-.2z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="m268.4 236.3 3.5-10.1 4.3 1.5a8 8 0 0 1 2.2 1c.4.4.7.9.9 1.5s0 1.1-.2 1.7c-.2.8-.7 1.3-1.3 1.6a3 3 0 0 1-2.3.3l.8 1.2.6 2 .5 2.4-2.4-.8-.7-2.7a14.5 14.5 0 0 0-.6-1.9 1.6 1.6 0 0 0-.4-.6 3.4 3.4 0 0 0-1-.5l-.4-.1-1.5 4.2-2-.7z"
              />
              <path
                fill="#fff"
                d="m272.4 231.2 1.5.5 1.9.5c.3 0 .5 0 .7-.2l.5-.7v-1a1.3 1.3 0 0 0-.6-.6l-1.5-.5-1.6-.6-.9 2.6z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="M280.9 235.9a6.8 6.8 0 0 1 1.3-2.5c.3-.5.8-.8 1.3-1.1a4.2 4.2 0 0 1 1.6-.5c.7 0 1.5 0 2.3.3a5 5 0 0 1 3.2 2.5c.6 1.2.7 2.7.1 4.3a5.6 5.6 0 0 1-2.5 3.5 5 5 0 0 1-4 .2 5 5 0 0 1-3.2-2.5 5.5 5.5 0 0 1-.1-4.2z"
              />
              <path
                fill="#fff"
                d="M283 236.5c-.3 1.1-.3 2 0 2.8.4.8 1 1.3 1.8 1.6.8.2 1.5.1 2.2-.3.7-.4 1.3-1.2 1.7-2.4.3-1.2.3-2.1 0-2.9a2.7 2.7 0 0 0-1.8-1.5 2.7 2.7 0 0 0-2.3.3c-.7.4-1.2 1.2-1.6 2.4z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="m301.7 250.8 4.9-9.5 4 2c1 .6 1.7 1 2 1.4.4.5.6 1 .7 1.5s0 1.2-.4 1.7c-.3.7-.8 1.2-1.5 1.5-.7.2-1.4.2-2.3-.1.3.4.5.9.6 1.3l.3 2.1.2 2.5-2.3-1.2-.3-2.8-.3-2a1.6 1.6 0 0 0-.4-.6 3.5 3.5 0 0 0-.9-.6l-.4-.2-2 4-1.9-1z"
              />
              <path
                fill="#fff"
                d="m306.4 246.3 1.4.7 1.8.8c.3 0 .5 0 .7-.2a1.5 1.5 0 0 0 .8-1.5 1.3 1.3 0 0 0-.6-.7 21 21 0 0 0-1.3-.8l-1.5-.7-1.3 2.4z"
              />
            </g>
            <g stroke-opacity=".5">
              <path
                fill="#309e3a"
                d="M341.2 270.3c.6-1 1.2-1.6 2-2a5 5 0 0 1 1.6-.7 4.2 4.2 0 0 1 1.6 0c.7.1 1.5.4 2.2 1a5 5 0 0 1 2.3 3.3 6 6 0 0 1-1.1 4.1 5.6 5.6 0 0 1-3.5 2.6 5 5 0 0 1-3.9-.9 5 5 0 0 1-2.3-3.3 5.5 5.5 0 0 1 1-4.1z"
              />
              <path
                fill="#fff"
                d="M343 271.4c-.6 1-.9 2-.8 2.8a3 3 0 0 0 1.3 2 2.7 2.7 0 0 0 2.2.4c.8-.2 1.6-.8 2.3-1.9.7-1 1-1.9.8-2.7 0-.8-.5-1.4-1.2-2s-1.5-.6-2.3-.4c-.8.2-1.5.8-2.2 1.8z"
              />
            </g>
            <path
              fill="#309e3a"
              d="m246.4 229 1.7-7.6 5.6 1.3-.3 1.3-4-1-.4 1.7 3.7.9-.3 1.3-3.7-1-.5 2.1 4.2 1-.3 1.3-5.7-1.3z"
            />
          </g>
        </svg>
      </button>
    </div>
  );
};

export default Language;
