import React from "react";
import { useTranslation } from "react-i18next";
import "./about.css";
import AboutImg from "../../assets/images/about/about.jpg";

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about" class="about">
      <div class="about container">
        <div class="section-title">
          <h2>{t('about title')}</h2>
        </div>
        <div class="about__container container grid">

          <div className="about__img-container">
            <img src={AboutImg} className="about__img" alt="" />
          </div>

          <div className="about__data">
            <p className="about__description" style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: t('about intro') }} />
            <ul>
              <li>
                <svg
                  width="10px"
                  height="10px"
                  viewBox="0 0 1024 1024"
                  class="icon"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                    fill="#000000"
                  />
                </svg>
                <strong> {t('about city')}</strong> {t('about location')}
              </li>
              <li>
                <svg
                  width="10px"
                  height="10px"
                  viewBox="0 0 1024 1024"
                  class="icon"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                    fill="#000000"
                  />
                </svg>
                <strong> E&#8209;mail:</strong> lucasrsilva.dev@gmail.com
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;