import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ThemeProvider } from "./theme/ThemeContext";
import "./App.css";
import Header from "./components/header/Header";
// import Home from "./components/home/Home";
import About from "./components/about/About";
import Education from "./components/education/Education";
import Certification from "./components/certification/Certification";
import Experience from "./components/experience/Experience";
import Projects from "./components/projects/ProjectsList";
// import CV from "./components/cv/Cv";
import Contact from "./components/contact/Contact";
import Preloader from "./components/preloader/Preloader";
import Footer from "./components/footer/Footer";
import Language from "./components/language/Language"

function App() {
  useEffect(() => {
    document.title = "Lucas Portfolio";
  }, []);

  return (
    <Router>
      <Preloader />
      {/* <ThemeProvider> */}
      <div className="wrapper">
        <Language />
        <Header />
        <Routes>
          <Route path="/" element={<About />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/education" element={<Education />} />
          <Route path="/certification" element={<Certification />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<Projects />} />
          {/* <Route path="/cv" element={<CV />} /> */}
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
      {/* </ThemeProvider> */}
    </Router>
  );
}

export default App;
