import React from 'react';

const Project = ({ image, title }) => {

  return (
    <div className="project">
      <img src={image} alt={title} />
      <h3>{title}</h3>
    </div>
  );
};

export default Project;