import React from "react";
import { useTranslation } from "react-i18next";
import "./certification.css";
import udemyImage from "../../assets/svg/certification/udemy.svg";
import nvidiaImage from "../../assets/svg/certification/nvidia.svg";

const Certification = () => {
  const { t } = useTranslation();

  return (
    <section id="certification" className="certification">
      <div className="certification container">
        <div className="section-title">
          <h2>{t("certification title")}</h2>
        </div>

        <div className="certification__container">
          <div className="certification__course container grid">
            <div className="certification__img-container">
              <img
                className="certification__img"
                src={nvidiaImage}
                width="90"
                height="90"
                alt="Nvidia logo"
              />
            </div>

            <div className="certification__course-info">
              <h5>
                <em>{t("certification_5 name")}</em>
              </h5>
              <div>
                <span>
                  <em>{t("certification_5 date")}</em>
                </span>
                <br />
                <a
                  href="https://courses.nvidia.com/certificates/e8f7cf459cb341ceaaad169e79237637/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <em>{t("certification_5 credential")}</em>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="certification__container">
          <div className="certification__course container grid">
            <div className="certification__img-container">
              <img
                className="certification__img"
                src={nvidiaImage}
                width="90"
                height="90"
                alt="Nvidia logo"
              />
            </div>

            <div className="certification__course-info">
              <h5>
                <em>{t("certification_6 name")}</em>
              </h5>
              <div>
                <span>
                  <em>{t("certification_6 date")}</em>
                </span>
                <br />
                <a
                  href="https://courses.nvidia.com/certificates/89fa2417ecd3402680da85f2070fbe33/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <em>{t("certification_6 credential")}</em>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="certification__container">
          <div className="certification__course container grid">
            <div className="certification__img-container">
              <img
                className="certification__img"
                src={udemyImage}
                width="90"
                height="90"
                alt="Udemy logo"
              />
            </div>

            <div className="certification__course-info">
              <h5>
                <em>{t("certification_1 name")}</em>
              </h5>
              <div>
                <span>
                  <em>{t("certification_1 date")}</em>
                </span>
                <br />
                <a
                  href="https://www.ude.my/UC-9f7e256e-ba54-4715-a34b-3cd1320c79e3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <em>{t("certification_1 credential")}</em>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="certification__container">
          <div className="certification__course container grid">
            <div className="certification__img-container">
              <img
                className="certification__img"
                src={udemyImage}
                width="90"
                height="90"
                alt="Udemy logo"
              />
            </div>

            <div className="certification__course-info">
              <h5>
                <em>{t("certification_2 name")}</em>
              </h5>
              <div>
                <span>
                  <em>{t("certification_2 date")}</em>
                </span>
                <br />
                <a
                  href="https://www.ude.my/UC-c0202928-edb6-4a82-bc83-8cbb3372ea69"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <em>{t("certification_2 credential")}</em>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="certification__container">
          <div className="certification__course container grid">
            <div className="certification__img-container">
              <img
                className="certification__img"
                src={udemyImage}
                width="90"
                height="90"
                alt="Udemy logo"
              />
            </div>

            <div className="certification__course-info">
              <h5>
                <em>{t("certification_3 name")}</em>
              </h5>
              <div>
                <span>
                  <em>{t("certification_3 date")}</em>
                </span>
                <br />
                <a
                  href="https://www.udemy.com/certificate/UC-0eebe7b5-2f4b-4d55-8bcc-c571e0405493/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <em>{t("certification_3 credential")}</em>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="certification__container">
          <div className="certification__course container grid">
            <div className="certification__img-container">
              <img
                className="certification__img"
                src={udemyImage}
                width="90"
                height="90"
                alt="Udemy logo"
              />
            </div>

            <div className="certification__course-info">
              <h5>
                <em>{t("certification_4 name")}</em>
              </h5>
              <div>
                <span>
                  <em>{t("certification_4 date")}</em>
                </span>
                <br />
                <a
                  href="https://ude.my/UC-f519652b-68c9-41ea-b1d2-c7e86547c94c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <em>{t("certification_4 credential")}</em>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        
        
      </div>
    </section >
  );
};

export default Certification;
