import React from "react";
import { useTranslation } from "react-i18next";
import "./experience.css";
import cnpqImage from "../../assets/images/experience/cnpq.png";
import cefetImage from "../../assets/images/education/cefet.png";
import cimoImage from "../../assets/svg/experience/cimo.svg";

const Experience = () => {
  const { t } = useTranslation();

  return (
    <section id="experience" className="experience">
      <div className="experience container">
        <div className="section-title">
          <h2>{t("experience title")}</h2>
        </div>

        <div className="experience__container">
          <div className="experience__course container grid">
            <div className="experience__img-container">

              <a
                href="https://cimo.ipb.pt/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="experience__img"
                  src={cimoImage}
                  alt="CIMO logo"
                />
              </a>

            </div>

            <div className="experience__course-info">
              <h5>
                <em>{t("experience_3 role")}</em>
              </h5>
              <span className="date">
                <em>{t("experience_3 date")}</em>
              </span>
              <br /><br />
              <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: t('experience_3 description') }} />
            </div>
          </div>
        </div>

        <div className="experience__container">
          <div className="experience__course container grid">
            <div className="experience__img-container">

              <a
                href="https://www.gov.br/cnpq/pt-br"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="experience__img"
                  src={cnpqImage}
                  alt="CNPq logo"
                />
              </a>

            </div>

            <div className="experience__course-info">
              <h5>
                <em>{t("experience_1 role")}</em>
              </h5>
              <span className="date">
                <em>{t("experience_1 date")}</em>
              </span>
              <br /><br />
              <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: t('experience_1 description') }} />
            </div>
          </div>
        </div>

        <div className="experience__container">
          <div className="experience__course container grid">
            <div className="experience__img-container">

              <a
                href="https://www.divinopolis.cefetmg.br/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  className="experience__img"
                  src={cefetImage}
                  alt="CEFET-MG logo"
                />
              </a>

            </div>

            <div className="experience__course-info">
              <h5>
                <em>{t("experience_2 role")}</em>
              </h5>
              <span className="date">
                <em>{t("experience_2 date")}</em>
              </span>
              <br /><br />
              <p style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: t('experience_2 description') }} />
            </div>
          </div>
        </div>

      </div>
    </section >
  );
};

export default Experience;
