import React, { useRef, useCallback } from 'react';
import './projectModal.css';
import { useTranslation } from "react-i18next";

const ProjectModal = ({ project, onClose }) => {
  const modalRef = useRef(null);
  const { t } = useTranslation();

  const handleOutsideClick = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    const handleClickOutside = (event) => handleOutsideClick(event);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleOutsideClick]);

  const { title, description, youtubeUrl } = project;

  return (
    <div className="modal">
      <div ref={modalRef} className="modal-content" style={{ overflowY: 'auto', maxHeight: '80vh' }}>
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{t(title)}</h2>
        <p dangerouslySetInnerHTML={{ __html: t(description) }} style={{ textAlign: 'justify' }}></p>
        {youtubeUrl && <iframe width="560" height="315" src={youtubeUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
        {/* <br /> */}
        {/* <p>{t("project modal github")} <a href={githubUrl} target="_blank" rel="noopener noreferrer"><em>{t("project modal githubrepo")}</em></a></p> */}
      </div>
    </div>
  );
};

export default ProjectModal;