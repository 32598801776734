import React, { useEffect } from "react";
import "./preloader.css";

function Preloader() {
  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const preloader = document.getElementById("preloader");

    if (!isMobile) {
      setTimeout(() => {
        preloader.classList.add("preloaded");
      }, 1400);
      setTimeout(() => {
        preloader.remove();
      }, 3000);
    } else {
      preloader.remove();
    }
  }, []);

  return (
    <div id="preloader">
      <div className="loader_line"></div>
    </div>
  );
}

export default Preloader;
